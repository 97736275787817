import './styles/main.scss';
import { useState, useEffect } from 'react';

interface dataUserType {
  username: string,
  id: number,
  balance: number,
  bet : {
    id: string,
    price: string,
    coefficient: string,
    potential_win: string,
    created_at: string
  },
  email: string
}

export const App = () => {
  const isDarkMode = window.matchMedia("(prefers-color-scheme: dark)").matches
  // @ts-ignore
  const [isAuth, setIsAuth] = useState(false);

  const [dataUser, setDataUser] = useState <dataUserType | null>(null)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    console.log(token);
    if (token) {
      fetch('https://zeroone.su/api/v1/get/desktop/info/', {
        method: 'GET',
        headers: {
          'Token': `${token}`,
          'Content-Type': 'application/json',
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (data.bet) {
            data.bet.created_at = new Date(data?.bet.created_at).toLocaleDateString('ru');
            //  @ts-ignore
            window.chrome.webview.postMessage(data.bet.id.toString())
          }
          setDataUser(data)
          setIsAuth(true)
        });
    }
  }, []);

  const onClick = () => {
      // @ts-ignore
      window.chrome.webview.postMessage("Auth");
  };

  const onNewBet = ()=>{
    // @ts-ignore
    window.chrome.webview.postMessage("NewBet");
  }

  const startBet = () => {
    // @ts-ignore
    window.chrome.webview.postMessage("Bet");
  }

  const onExit = () => {
      // @ts-ignore
      window.chrome.webview.postMessage("Exit");
  }

  return !isAuth
    ? (
      <div className="auth-form">
        <div className="auth-form__header">
          <div className="auth-form__img">
            {isDarkMode
              ? <img src="/zeroone.svg" alt="zeroone" />
              : <img src="/zeroone-light.svg" alt="zeroone" />}
          </div>
          <div onClick={onExit} className="auth-form__button-close">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 3L3 9" stroke="white" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
              <path d="M9 9L3 3" stroke="white" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
            </svg>
          </div>
        </div>
        <div className="auth-form__body">
          <div className="welcome">
            <h4 className="welcome__heading">
              Пожалуйста, <br />
              авторизуйтесь <br />
              в системе
            </h4>
            <p className="welcome__text">
              Откроется веб-браузер, <br />
              где вы сможете войти <br />
              в свою учетную запись
            </p>
            <button className="button" onClick={onClick}>Войти</button>
          </div>
        </div>
      </div>
    )
    : (
      <div className="auth-form">
        <div className="auth-form__header">
          <div className="auth-form__img">
            {isDarkMode
              ? <img src="/zeroone.svg" alt="zeroone" />
              : <img src="/zeroone-light.svg" alt="zeroone" />}
          </div>
          <div className="auth-form__button-close" onClick={onExit}>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9 3L3 9" stroke="white" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
              <path d="M9 9L3 3" stroke="white" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
            </svg>
          </div>
        </div>
        <div className="auth-form__body body">
          <div className="balance">
            <div className="balance__heading">Total balance</div>
            <div className="balance__body">
            <div className="balance__currency">{dataUser?.balance} <sup>ETH</sup></div>
              <div className="balance__total"></div>
            </div>
          </div>
          {dataUser?.bet ? (
            <div className="bet">
              <div className="bet__header">
                <div className="bet__game-image">
                  <img src="/dota.png" alt="dota" />
                </div>
                <div className="bet__game-info game-info">
                  <span className="game-info__name">Dota <span className="game-info__status" /></span>
                  <span className="game-info__starts-at">{dataUser?.bet.created_at}</span>
                </div>
              </div>
              <div className="bet__summary">
                <span>Win:</span>
                <span>{dataUser?.bet?.potential_win} ETH</span>
              </div>
              <button className="button" onClick={startBet}>
                Начать игру
              </button>
            </div>
          ) : (
            <div className="no-bet">
              <button className="button" onClick={onNewBet}>Сделать ставку</button>
            </div>
          )}
        </div>
      </div>
    );
};